


















































































































































.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .upload-add {
    width: 90px;
    height: 90px;
    background-image: url(https://mktv-in-cdn.mockuai.com/16170761017728997.png);
    background-size: cover;
  }
  .preview {
    position: relative;
    width: 90px;
    height: 90px;
    margin-right: 30px;
    margin-bottom: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #f7f7f7;
    .close-icon {
      position: absolute;
      top: -8px;
      right: -8px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .example {
    text-decoration: none;
    margin-left: 20px;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #007cff;
  }
  .tips {
    margin-left: 20px;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #999;
  }
}

